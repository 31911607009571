import React from "react";
import { Link, StaticQuery, graphql } from "gatsby";

export default () => (
  <StaticQuery
    query={graphql`
      query {
        image: file(relativePath: { eq: "sean.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 1000) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => (
      <nav
        className="navbar is-transparent"
        role="navigation"
        aria-label="main-navigation"
      >
        <div className="container px-0">
          <div className="navbar-content">
            <div className="navbar-brand">
              <Link to="/" title="Logo">
                <img
                  src="/img/logo_white.png"
                  alt="The Sean Burke Show"
                  className="logo img-fluid"
                />
              </Link>
            </div>
            <div id="navMenu" className="navbar-menu">
              <Link to="/" className="navbar-item" activeClassName="active">
                SHOWS
              </Link>
              <Link
                to="/about/"
                className="navbar-item"
                activeClassName="active"
              >
                ABOUT
              </Link>
              {/* <Link className="navbar-item" to="/products">
                  Products
                 </Link>
                <Link className="navbar-item" to="/contact">
                  Contact
                 </Link>
                <Link className="navbar-item" to="/contact/examples">
                  Form Examples
                 </Link> */}
            </div>
          </div>
        </div>
      </nav>
    )}
  />
);
